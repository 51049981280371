import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ContactTemplate from '../templates/contact-template';

const Contact = ({ data }) => {
	const content = data.pagesJson;
	return (
		<Layout popup={true}>
			<SEO title="Contact Us" description={content.description} />
			<ContactTemplate copy={content} />
		</Layout>
	);
};

export default Contact;

export const pageQuery = graphql`
	query ContactPage {
		pagesJson(page: { eq: "contact" }) {
			description
			masthead {
				title
				img
			}
			split {
				left {
					title
					copy
				}
				right {
					title
					copy
					phone {
						raw
						format
					}
				}
			}
			form {
				title
				address
				phone {
					raw
					format
				}
				tollFree {
					raw
					format
				}
				formImg
				contactImg
			}
		}
	}
`;

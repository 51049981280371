import React from 'react';

import Masthead from '../components/masthead';
import Copy from '../components/contact/copy';
import Form from '../components/contact/form';

const ContactTemplate = ({ copy }) => {
	const { masthead, split, form } = copy;
	return (
		<>
			<Masthead title={masthead.title} img={masthead.img} />
			<Copy left={split.left} right={split.right} />
			<Form
				title={form.title}
				address={form.address}
				phone={form.phone}
				tollFree={form.tollFree}
				formImg={form.formImg}
				contactImg={form.contactImg}
				className="contact"
			/>
		</>
	);
};

export default ContactTemplate;

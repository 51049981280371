import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const StyledHomeForm = styled.div`
	display: flex;
	.left {
		background-image: url(${(p) => p.formImg});
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		width: 50%;
		padding: 4rem ${(p) => p.theme.gutter};
		label {
			display: block;
			color: #fff;
			margin: 15px 0 5px;
			&::after {
				content: '*:';
			}
			&.phone::after {
				content: '';
			}
		}
		input {
			width: 100%;
			padding: 5px;
			height: 2rem;
		}
		textarea {
			width: 100%;
			resize: none;
			padding: 5px;
			height: 5rem;
			margin-bottom: 1rem;
		}
		button {
			padding: 0.5rem 2.5rem;
			cursor: pointer;
		}
		.thanks {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			color: #fff;
			h2 {
				color: #fff;
			}
		}
	}

	.right {
		width: 50%;
		background-image: url(${(p) => p.contactImg});
		background-position: top center;
		background-size: auto 46%;
		background-repeat: no-repeat;
		position: relative;
		.contact {
			height: 60%;
			padding: 0 4rem;
			display: flex;
			align-items: center;
			bottom: 0;
			width: 100%;
			position: absolute;
			background-color: ${(p) => p.theme.color.purple};
			h2 {
				color: #fff;
				margin-bottom: 1rem;
			}
			a {
				color: #fff;
				transition: 0.5s;
				position: relative;
				&::after {
					content: '';
					background-color: #fff;
					transition: 0.5s;
					width: 100%;
					height: 1px;
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					opacity: 0;
				}
				&:hover::after {
					opacity: 1;
				}
			}
			.phone a {
				margin-left: 5px;
			}
			.address {
				white-space: pre-wrap;
				margin-bottom: 1rem;
				a {
					&::before {
						content: '';
						background-color: #fff;
						transition: 0.5s;
						width: 85%;
						height: 1px;
						position: absolute;
						top: 50%;
						left: 0;
						right: 0;
						opacity: 0;
					}
					&:hover::before {
						opacity: 1;
					}
				}
			}
			p {
				color: #fff;
				line-height: 1.5rem;
			}
			.social {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				a {
					padding: 0 5px;
					background-color: #fff;
					border-radius: 30px;
					width: 35px;
					height: 35px;
					display: flex;
					justify-content: center;
					align-items: center;
					margin: 1rem 3px 0;
					transition: 0.5s;
					img {
						position: absolute;
						width: 30px;
						height: 30px;
						&.linkedin {
							width: 45px;
							height: 45px;
						}
					}
					&:hover {
						opacity: 0.75;
						&::after {
							content: none;
						}
					}
				}
			}
		}
	}
	@media all and (min-width: 1100px) {
		.right {
			background-size: 100% auto;
		}
	}
	@media all and (min-width: 1200px) {
		.left {
			padding: 4rem ${(p) => p.theme.gutterWide};
		}
	}
	@media all and (min-width: 1600px) {
		.left {
			padding: 6rem ${(p) => p.theme.gutterXWide};
			label {
				font-size: 1.3rem;
				margin: 20px 0 10px;
			}
			button {
				border-radius: 30px;
			}
		}
	}
	@media all and (max-width: 620px) {
		flex-wrap: wrap;
		.left {
			width: 100%;
			input,
			textarea {
				width: 80%;
			}
		}
		.right {
			width: 100%;
			.contact {
				height: auto;
				position: relative;
				padding: 3rem ${(p) => p.theme.gutter};
			}
		}
	}
	@media all and (max-width: 500px) {
		.left {
			padding: 2rem ${(p) => p.theme.gutterMobile};
		}
		.right .contact {
			padding: 2rem ${(p) => p.theme.gutterMobile};
		}
	}
	@media all and (max-width: 410px) {
		.left {
			input,
			textarea {
				width: 90%;
			}
		}
		.right {
			h2 {
				width: 180px;
			}
		}
	}
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		.right .contact .social a img {
			left: 3px;
			top: 2px;
			&.linkedin {
				left: 1px;
				top: -4px;
			}
		}
	}
`;

const HomeForm = ({ title, address, phone, tollFree, contactImg, formImg }) => {
	const [thanks, setThanks] = useState(false);
	const [height, setHeight] = useState(371);

	useEffect(() => {
		setThanks(window.location.search === '?thanks=true');
		const handleResize = () => {
			if (window.innerWidth > 1600) setHeight(431);
		};
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});

	return (
		<StyledHomeForm contactImg={contactImg} formImg={formImg} id="form">
			<div className="left">
				{thanks && (
					<div
						className="thanks"
						id="mobileThanksHeight"
						style={{ height: `${height}px` }}
					>
						<h2>Thanks for submitting.</h2>
						<p>You will hear from us shortly.</p>
					</div>
				)}

				{!thanks && (
					<form
						method="post"
						name="Contact Form"
						data-netlify="true"
						data-netlify-honeypot="bot-field"
						action="/contact/?thanks=true#form"
					>
						<input type="hidden" name="form-name" value="Contact Form" />
						<input type="hidden" name="bot-field" />
						<div className="field">
							<label htmlFor="name">Name</label>
							<input type="text" id="name" name="name" required aria-required="true" />
						</div>
						<div className="field">
							<label htmlFor="email">Email</label>
							<input type="email" id="email" name="email" required aria-required="true" />
						</div>
						<div className="field">
							<label htmlFor="phone" className="phone">
								Phone:
							</label>
							<input type="text" id="phone" name="phone" />
						</div>
						<div className="field">
							<label htmlFor="message">Message</label>
							<textarea type="text" id="message" name="message" required aria-required="true" />
						</div>
						<button className="redButton" type="submit">
							SUBMIT
						</button>
					</form>
				)}
			</div>
			<div className="right">
				<div className="contact">
					<div className="text-container">
						<h2>{title}</h2>
						<p className="address">
							<a href="https://goo.gl/maps/14eC3Purew6QxU1w6">{address}</a>
						</p>
						<p className="phone">
							Telephone:
							<a href={`tel:${phone.raw}`}>{phone.format}</a>
						</p>
						<p className="phone">
							Toll Free:
							<a href={`tel:${tollFree.raw}`}>{tollFree.format}</a>
						</p>
						<div className="social">
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://twitter.com/nafreightgroup"
								aria-label="Twitter - new window"
							>
								<img
									className="twitter"
									src="/img/twitter-icon-purple.svg"
									alt="twitter icon"
								/>
							</a>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://www.facebook.com/northamericanfreightgroup/"
								aria-label="Facebook - new window"
							>
								<img
									className="facebook"
									src="/img/facebook-icon-purple.svg"
									alt="facebook icon"
								/>
							</a>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://www.linkedin.com/company/north-american-freight-forwarding-inc/"
								aria-label="LinkedIn - new window"
							>
								<img
									className="linkedin"
									src="/img/linkedin-icon-purple.svg"
									alt="linkedin icon"
								/>
							</a>
						</div>
					</div>
				</div>
			</div>
		</StyledHomeForm>
	);
};

export default HomeForm;

import React from 'react';
import styled from 'styled-components';
import convertBold from '../../util/convertBold';

const StyledContactCopy = styled.div`
	padding: 4rem ${(p) => p.theme.gutter};
	display: flex;
	p {
		line-height: 1.6rem;
	}
	.left {
		width: 30%;
		margin-right: 2rem;
		padding-right: 2rem;
		border-right: 2px solid #ddd;
	}
	.right {
		width: 70%;
		p {
			margin-bottom: 1rem;
		}
		a {
			font-size: 1.2rem;
		}
	}

	@media all and (min-width: 1200px) {
		padding: 4rem ${(p) => p.theme.gutterWide};
	}
	@media all and (min-width: 1600px) {
		padding: 6rem ${(p) => p.theme.gutterXWide};
	}
	@media all and (max-width: 1050px) {
		.left {
			width: 35%;
		}
		.right {
			width: 65%;
		}
	}
	@media all and (max-width: 768px) {
		flex-wrap: wrap;
		.left {
			width: 100%;
			border: none;
			padding-bottom: 2rem;
			padding-right: 0;
			margin-right: 0;
		}
		.right {
			width: 100%;
		}
	}
	@media all and (max-width: 500px) {
		padding: 3rem ${(p) => p.theme.gutterMobile};
	}
`;

const ContactCopy = ({ left, right }) => {
	return (
		<StyledContactCopy>
			<div className="left">
				<h2
					dangerouslySetInnerHTML={{
						__html: convertBold(left.title),
					}}
				/>
				<p role="heading">{left.copy}</p>
			</div>
			<div className="right">
				<h2
					dangerouslySetInnerHTML={{
						__html: convertBold(right.title),
					}}
				/>
				<p role="heading">{right.copy}</p>
				<a className="phone" href={`tel:${right.phone.raw}`}>
					{right.phone.format}
				</a>
			</div>
		</StyledContactCopy>
	);
};

export default ContactCopy;
